
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import { apiGameHelpDelete, apiGameHelpLists, apiGameHelpIsShow } from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'


@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData
  }
})
export default class GameHelpLists extends mixins() {
  /** S Data **/

      // 分页
  pager: RequestPaging = new RequestPaging()

  /** E Data **/

  /** S Methods **/

  // 更改显示开关状态
  changeStatus(value: 0 | 1, row: any) {
    apiGameHelpIsShow({
      id: row.id,
      aid: '0',
    })
        .then(() => {
          this.getGameHelpList()
        })
        .catch((err: any) => {
          console.log('err', err)
        })
  }

  // 新增资讯文章
  goGameHelpAdd() {
    this.$router.push({
      path: '/setting/game_help/game_help_edit',
      query: {
        mode: PageMode.ADD,
        aid: '0',
      }
    })
  }

  // 编辑资讯文章
  goGameHelpEdit(data: any) {
    this.$router.push({
      path: '/setting/game_help/game_help_edit',
      query: {
        id: data.id,
        mode: PageMode.EDIT,
        aid: '0',
      }
    })
  }

  // 获取资讯文章列表
  getGameHelpList() {
    return this.pager
        .request({
          callback: apiGameHelpLists,
          params: {
            aid: '0',
          }
        })
        .catch((err: any) => {
          console.log('err', err)
        })
  }

  // 删除资讯文章
  onGameHelpDel(row: any) {
    apiGameHelpDelete({
      id: row.id,
      aid: '0',
    }).then(() => {
      this.getGameHelpList()
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  async created() {
    this.loadData()
  }
  /** E Life Cycle **/

  loadData() {
    return Promise.all([
      this.getGameHelpList(),
    ])
  }

  async onTabClick(event: any) {
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.5)',
      customClass: 'switch-loading'
    })
    await this.loadData()
    loading.close()
  }
}
